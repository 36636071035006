import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { intersection } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import { AccountCircle } from '@material-ui/icons';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import { FormattedMessage } from 'react-intl';

import { logout } from 'store/action-creators/auth/auth.js';
import { useTorque } from '@torquecloud/torque-react-js'

const MenuLink = {
  textDecoration: 'none',
  color: '#000000',
};

export const UserCard = () => {
  const { torque, refreshTorqueUser } = useTorque();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const canAccessAdminPanel = (u) => u && u.is_superuser;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  if (!user) {
    return null;
  }

  const { firstName, lastName, avatar } = user;

  const fullName =
    lastName !== undefined ? [firstName, lastName].join(' ') : firstName;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const profileImage = avatar ? `${process.env.REACT_APP_API}${avatar}` : null;

  return (
    <div>
      <IconButton
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color="inherit"
      >
        {profileImage ? (
          <Avatar alt="User Avatar" src={profileImage}/>
        ) : (
          <AccountCircle/>
        )}
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>{fullName}</MenuItem>
        {canAccessAdminPanel(user) && (
          <Link style={MenuLink} to="/admin/users">
            <MenuItem onClick={handleClose}>
              <FormattedMessage
                id="header.menu.admin"
                defaultMessage="Admin"
              />
            </MenuItem>
          </Link>
        )}
        <Link style={MenuLink} to="/settings/user">
          <MenuItem onClick={handleClose}>
            <FormattedMessage
              id="header.menu.settings"
              defaultMessage="My Settings"
            />
          </MenuItem>
        </Link>
        <MenuItem onClick={() => dispatch(logout(torque, refreshTorqueUser))}>
          <FormattedMessage id="header.menu.logout" defaultMessage="Logout" />
        </MenuItem>
      </Menu>
    </div>
  );
};
