import React from 'react';
import { Dialog } from '@material-ui/core';
import { ModalConsumer } from 'contexts';
import { CrossIcon } from 'components';

export const ModalRoot = () => (
  <ModalConsumer>
    {({ component: Component, modalProps, hideModal }) => (Component ? (
      <>
        <Dialog
          className={modalProps.wrapperClasses}
          onBackdropClick={hideModal}
          onClose={hideModal}
          open
          maxWidth="md"
          fullWidth
          style={{ padding: 30 }}
        >
          <div className="text-right">
            <CrossIcon
              className="w-6 h-6 mt-2 mr-2 text-grey absolute pin-r pin-t cursor-pointer closeBtn"
              onClick={hideModal}
            />
          </div>
          <Component {...modalProps} />
        </Dialog>
      </>
    ) : null)}
  </ModalConsumer>
);
