import React, { lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import { AuthGuardFunctional, AuthGuardMemberFunctional } from 'components'
import { NewDashboardLayout } from 'layouts/Dashboard'

const LandingPage = lazy(() => import('pages/Landing'));
const NotFound = lazy(() => import('pages/NotFound/NotFound'));
const SettingsRoutes = lazy(() => import('pages/Settings/SettingsRoutes'));
const OrganizationsRoutes = lazy(() =>
  import('pages/Organizations/OrganizationsRoutes')
);
const OrganizationsDetailRoutes = lazy(() =>
  import('pages/Organizations/OrganizationsDetailRoutes')
);
const TorqueAuthCallback = lazy(() => import('./pages/Authentication/TorqueAuthCallback'))
const TorqueLogin = lazy(() => import('./pages/Authentication/TorqueLogin'))
const UsersRoutes = lazy(() => import('pages/Users/UsersRoutes'));
const UserDetailRoutes = lazy(() => import('pages/Users/UsersDetailRoutes'));
const PortfolioListPage = lazy(() =>
  import('pages/Portfolio/PortfoliotListPage')
);
const GDPRpolicyRoutes = lazy(() => import('pages/GDPRpolicy/GDPRpolicyRoutes'));
const ProjectPriorityPage = lazy(() =>
  import('pages/ProjectPriority/ProjectPriority')
);
const ProjectOptimizationPage = lazy(() =>
  import('pages/ProjectOptimizer/ProjectOptimizer')
);
const ProjectSimulationPage = lazy(() =>
  import('pages/ProjectSimulation/ProjectSimulation')
);
const TaskPriorityPage = lazy(() =>
  import('pages/TaskPriority/TaskPriority')
);
const TaskOptimizationPage = lazy(() =>
  import('pages/TaskOptimization/TaskOptimization')
);

const PortfolioAdminPage = lazy(() => import('pages/Admin/Portfolio/PortfolioAdmin'));
const ProjectAdminPage = lazy(() => import('pages/Admin/Project/ProjectAdmin'));

const withDashboardAdmin = (ContentComponent) => {
  return (props) => (
    <AuthGuardFunctional>
      <NewDashboardLayout>
        <ContentComponent {...props} />
      </NewDashboardLayout>
    </AuthGuardFunctional>
  );
};

const withDashboardMember = (ContentComponent) => {
  return (props) => (
    <AuthGuardMemberFunctional>
      <NewDashboardLayout>
        <ContentComponent {...props} />
      </NewDashboardLayout>
    </AuthGuardMemberFunctional>
  );
};

const OrganizationsComponent = withDashboardAdmin(OrganizationsRoutes);
const OrganizationsDetailComponent = withDashboardAdmin(
  OrganizationsDetailRoutes
);
const UsersComponent = withDashboardAdmin(UsersRoutes);
const UserDetailComponent = withDashboardAdmin(UserDetailRoutes);
const PortfolioAdminComponent = withDashboardAdmin(PortfolioAdminPage);
const ProjectAdminComponent = withDashboardAdmin(ProjectAdminPage);

const PortfolioListComponent = withDashboardMember(PortfolioListPage);
const SettingsWithDashboard = withDashboardMember(SettingsRoutes);
const GDPRpolicyComponent = withDashboardMember(GDPRpolicyRoutes);
const ProjectPriorityComponent = withDashboardMember(ProjectPriorityPage);
const ProjectOptimizationComponent = withDashboardMember(
  ProjectOptimizationPage
);
const ProjectSimulationComponent = withDashboardMember(ProjectSimulationPage);
const TaskPriorityComponent = withDashboardMember(TaskPriorityPage);
const TaskOptimizationComponent = withDashboardMember(TaskOptimizationPage);


export const Routes = () => {
  return (
    <Switch>
      <Route
        exact
        path="/torque/auth-callback"
        component={TorqueAuthCallback}
      />
      <Route
        exact
        path="/login"
        component={TorqueLogin}
      />

      {/* Member Routes */}
      <Route exact path="/" component={LandingPage} />
      <Route
        exact
        path="/portfolio-prioritization/:id"
        component={ProjectPriorityComponent}
      />
      <Route
        exact
        path="/portfolio-optimization/:id"
        component={ProjectOptimizationComponent}
      />
      <Route
        exact
        path="/project-simulation/:id"
        component={ProjectSimulationComponent}
      />
      <Route
        exact
        path="/project-prioritization/:id"
        component={TaskPriorityComponent}
      />
      <Route
        exact
        path="/project-optimization/:id"
        component={TaskOptimizationComponent}
      />

      <Route exact path="/portfolios" component={PortfolioListComponent}/>
      <Route path="/settings" component={SettingsWithDashboard}/>
      <Route exact path="/gdpr-policy" component={GDPRpolicyComponent}/>

      {/* Admin Routes */}
      <Route
        exact
        path="/admin/organizations"
        component={OrganizationsComponent}
      />
      <Route
        exact
        path="/admin/organizations/:id"
        component={OrganizationsDetailComponent}
      />
      <Route
        exact
        path="/admin/organizations/:id/edit"
        component={OrganizationsDetailComponent}
      />
      <Route exact path="/admin/users" component={UsersComponent} />
      <Route exact path="/admin/users/add" component={UserDetailComponent} />
      <Route path="/admin/users/:id" component={UserDetailComponent} />

      <Route exact path="/admin/portfolios" component={PortfolioAdminComponent} />
      <Route exact path="/admin/projects" component={ProjectAdminComponent} />

      {/* 404 route */}
      <Route path="*" exact render={() => <NotFound />} />
    </Switch>
  );
};
