// eslint-disable-next-line
import React from 'react';
import axios from 'axios';
import { makeRequest } from './index';

export const apiRequestAction = ({
  endpoint,
  method,
  action,
  data = false,
}) => async (dispatch, getState) => {
  const instance = axios.create({
    baseURL: `${process.env.REACT_APP_API}/api/v1`,
  });

  const requestConfig = {
    method,
    url: endpoint,
  };

  if (data) {
    requestConfig.data = data;
  }

  requestConfig.headers = {};
  requestConfig.headers.Accept = 'application/json';

  const token = await getState().auth.token.auth_token;
  if (token) {
    requestConfig.headers.Authorization = `Bearer ${token}`;
  }

  try {
    const response = await dispatch(
      makeRequest(action, () => instance(requestConfig)),
    );

    dispatch({
      type: action,
      payload: response,
    });

    return response;
  } catch (err) {
    console.error(err); //eslint-disable-line
    throw err;
  }
};
