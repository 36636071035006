import { taskActions as actions, projectActions } from 'store/actions';
import {
  createReducer,
  initState,
  updateEntry,
  deleteEntry,
  createEntry,
  updateSingleEntry,
} from 'store/reducers/utilities';

export const taskReducer = createReducer(initState(), {
  [actions.FETCH_ALL_PROJECT_TASKS]: updateEntry,
  [actions.FETCH_ALL_TASKS]: updateEntry,
  [actions.CREATE_TASK]: createEntry('list'),
  [actions.UPDATE_TASK]: updateSingleEntry('list'),
  [actions.DELETE_TASK]: deleteEntry('list'),
  [actions.UPDATE_TASK_COMPARISON]: updateEntry,
  [actions.CREATE_COMMENT]: updateSingleEntry('list'),
  [actions.DELETE_COMMENT]: updateSingleEntry('list'),
  [projectActions.FETCH_TASKS]: updateEntry,
});
