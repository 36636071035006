const initState = () => ({
  pending: false,
  accepted: false,
  error: null,
});

const getError = (state, val) => ({
  ...state.error,
  ...val,
});

export default function GDPR(state = initState(), action = {}) {
  switch (action.type) {
    case 'ACCEPT_GDPR_PENDING':
      return {
        ...state,
        ...{
          pending: action.payload,
          error: getError(state, action.payload),
        },
      };
    case 'ACCEPT_GDPR_SUCCESS':
      return {
        ...state,
        ...{
          accepted: action.payload,
          error: getError(state, action.payload),
        },
      };
    default:
      return state;
  }
}
