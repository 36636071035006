import { userActions as actions } from 'store/actions';
import { createReducer } from 'store/reducers/utilities';
import { clone, has, isArray, unionBy } from 'lodash';

const initState = () => ({
  pending: false,
  created_data: [],
  list: [],
  all: [],
  user: {},
  error: null,
  commentsOpen: false,
});

const updateEntry = (state, { payload, type }) => {
  let payloadClone = clone(payload);

  if (has(payloadClone, 'data')) {
    payloadClone = payloadClone.data;
  }

  if (state.list && state.list.length > 0) {
    payloadClone = unionBy(Array.isArray(payloadClone) ? payloadClone : [payloadClone], state.list, 'id');
  }

  return {
    ...state,
    ...{
      list: isArray(payloadClone) ? payloadClone : [payloadClone],
    },
  };
};

const toggleComments = (state) => {
  return {
    ...state,
    commentsOpen: !state.commentsOpen,
  };
};

export const userReducer = createReducer(initState(), {
  [actions.FETCH_ALL_USERS]: updateEntry,
  [actions.FETCH_USER]: updateEntry,
  [actions.UPDATE_USER]: updateEntry,
  [actions.DELETE_USER]: updateEntry,
  [actions.CREATE_USER]: updateEntry,
  [actions.CHANGE_PASSWORD]: updateEntry,
  [actions.TOGGLE_COMMENTS]: toggleComments,
});
