import { createMuiTheme } from '@material-ui/core/styles';

const primary = '#323C47';
const secondary = '#00BE9C';
const error = '#D50000';
const baseBoxShadow = '6px 0px 18px rgba(0, 0, 0, 0.06);';
const mediumBoxShadow = '6px 0px 18px rgba(0, 0, 0, 0.33);';
const systemFontStack = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"';

const theme = createMuiTheme({
  typography: {
    htmlFontSize: 14,
    fontSize: 14,
    fontFamily: ['Lato', systemFontStack].join(','),
    fontFamilySerif: ['Lato', systemFontStack].join(','),
    h2: {
      color: primary,
      fontWeight: 600,
      fontSize: '1.5em', // 24px
      letterSpacing: '1px',
    },
    h3: {
      color: primary,
      fontWeight: 600,
      fontSize: '1.25em', // 20px
      borderBottom: `1px solid ${secondary}`,
      width: 'fit-content',
      paddingBottom: '5px',
      letterSpacing: '0.5px',
    },
    h4: {
      color: primary,
      fontWeight: 600,
      fontSize: '1.125em', // 18px
    },
    body1: {
      fontSize: '0.875em',
    },
    caption: {
      color: '#878787',
      fontSize: '0.8125em', // 13px
    },
  },
  palette: {
    primary: {
      main: primary,
      hover: '#173148',
    },
    secondary: {
      main: secondary,
      contrastText: '#ffffff',
    },
    warning: {
      main: '#ffc107',
      hover: '#e0a800',
    },
    error: {
      main: error,
    },
    success: {
      main: '#28a745',
      hover: '#218838',
    },
    white: {
      main: '#ffffff',
      contrastText: '#000000',
    },
    text: {
      main: primary,
    },
  },
  borderRadius: {
    base: '7px',
    tab: '0 0 20px 20px',
  },
  boxShadow: {
    base: baseBoxShadow,
    medium: mediumBoxShadow,
  },
  border: {
    bottom: `2px solid ${secondary}`,
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'unset',
      },
    },
    MuiDrawer: {
      paper: {
        boxShadow: baseBoxShadow,
      },
    },
    MuiPaper: {
      root: {
        background: 'white',
      },
      rounded: {
        borderRadius: '7px',
      },
      elevation1: {
        boxShadow: baseBoxShadow,
      },
      elevation2: {
        boxShadow: 'none',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'capitalize',
        margin: '8px 0',
      },
      contained: {
        boxShadow: 'unset',
        backgroundColor: secondary,
        color: 'white',
        '&:hover': {
          backgroundColor: secondary,
        },
      },
    },
    MuiDialog: {
      paper: {
        padding: '24px',
        margin: 0,
      },
      paperFullWidth: {
        width: '100%',
      },
    },
  },
  props: {
    MuiButtonBase: {
      disableRipple: true,
    },
  },
});

export default theme;
