import React from 'react';
import { FormattedMessage } from 'react-intl';

import { DragOverlay } from 'components';

import './PictureUpload.scss';
import defaultProfileImage from 'images/default-profile-picture.jpeg';

export class PictureUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isHovering: false,
    };

    this.fileInputRef = React.createRef();
    this.handleDrop = this.handleDrop.bind(this);
    this.toggleHover = this.toggleHover.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
  }

  async handleDrop(e) {
    e.preventDefault();

    const { uploadHandler } = this.props;

    if (uploadHandler) {
      const fileData = new FormData();
      fileData.append('avatar', e.dataTransfer.items[0].getAsFile());
      await uploadHandler(fileData);
    }

    this.setState({ isHovering: false });
  }

  async handleFileUpload(e) {
    e.preventDefault();

    if (!e.target.files[0]) {
      return false;
    }

    const { uploadHandler } = this.props;

    const fileData = new FormData();
    fileData.append('avatar', e.target.files[0]);
    await uploadHandler(fileData);
  }

  toggleHover() {
    this.setState({ isHovering: !this.state.isHovering });

    return false;
  }

  render() {
    const { name, value, className = '' } = this.props;

    const { isHovering } = this.state;

    const currentImage = value
      ? `${process.env.REACT_APP_API}${value}`
      : defaultProfileImage;

    return (
      <label
        htmlFor={name}
        className={`cursor-pointer relative text-center ${className} `}
      >
        <DragOverlay
          onDragOver={(e) => e.preventDefault()}
          onDragEnter={this.toggleHover}
          onDragLeave={this.toggleHover}
          onDrop={this.handleDrop}
        >
          <div
            className="w-48 h-48 rounded-full mb-4 mx-auto relative overflow-hidden pointer-events-none">
            {isHovering && (
              <div className="bg-black absolute pin picture-overlay"/>
            )}
            <img
              src={currentImage}
              className="block uploaded-picture"
              alt="user avatar"
            />
          </div>
          <span className="block text-grey text-sm">
            <FormattedMessage
              id="pictureUpload.dragdrop"
              defaultMessage="Drag or click to update your profile picture"
            />
          </span>
        </DragOverlay>
        <input
          type="file"
          id={name}
          name={name}
          className="hidden"
          onChange={this.handleFileUpload}
        />
      </label>
    );
  }
}
