import { flashMessageActions as actions } from 'store/actions';

export const flashMessage = (type, message) => {
  const id = Date.now();

  return {
    type: actions.SHOW_MESSAGE,
    messageType: type,
    id,
    message,
  };
};

export const hideMessage = (id) => ({
  type: actions.HIDE_MESSAGE,
  id,
});
