import React from 'react';
import { Card } from 'components';
import { NavLink } from 'react-router-dom';
import { linkStyle } from 'constants/styles';
import { FormattedMessage } from 'react-intl';

const CardLink = ({ to, className = '', children }) => {
  return (
    <NavLink
      to={to}
      activeClassName="bg-blue-lightest"
      className={`block border-b border-grey-light p-4 ${linkStyle} ${className}`}
    >
      {children}
    </NavLink>
  );
};

export const Menu = (props) => {
  return (
    <Card>
      <CardLink to="/admin/organizations">
        <FormattedMessage
          id="organizations.organizations"
          defaultMessage="Organizations"
        />
      </CardLink>
      <CardLink to="/admin/users">
        <FormattedMessage id="users.users" defaultMessage="Users" />
      </CardLink>
      <CardLink to="/admin/portfolios">
        <FormattedMessage
          id="portfolios.name"
          defaultMessage="Portfolios"
        />
      </CardLink>
      <CardLink to="/admin/projects">
        <FormattedMessage
          id="projects.name"
          defaultMessage="Projects"
        />
      </CardLink>
    </Card>
  );
};
