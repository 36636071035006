import {
  strategyActions as actions,
  strategyComparisonActions,
  portfolioActions
} from 'store/actions';
import {
  createReducer,
  deleteEntry,
  initState,
  updateEntry,
  updateKeyList,
  updateSingleEntry,
} from 'store/reducers/utilities';
import { get, unionBy } from 'lodash';

const updateStrategyComparison = (state, { payload, type }) => {
  const oldList = state.list.slice();
  const newList = oldList.map((item, index) => {
    // Replace the item at index 2
    if (item.name === payload.name) {
      return payload;
    }
    // Leave every other item unchanged
    return item;
  });
  return {
    ...state,
    list: newList,
  };
};

const updateStrategyList = (state, { payload }) => {
  const data = (payload && payload.data) ? payload.data : payload;
  let strategies = [];

  if (Array.isArray(data)) {
    strategies = data.map(
      ({ strategies }) => strategies.map((strategy) => strategy),
    ).flat();
  } else {
    strategies = get(data, 'strategies');
  }

  const mergedLists = unionBy(strategies, state.list, 'id');

  return {
    ...state,
    list: mergedLists,
  };
};

export const strategyReducer = createReducer(initState(), {
  [actions.FETCH_ALL_STRATEGIES]: updateEntry,
  [actions.FETCH_STRATEGY]: updateEntry,
  [actions.UPDATE_STRATEGY]: updateEntry,
  // [actions.UPDATE_STRATEGY_COMPARISON]: updateEntry,
  [actions.DELETE_STRATEGY]: deleteEntry('list'),
  [actions.CREATE_STRATEGY]: updateEntry,
  [actions.CREATE_COMMENT]: updateSingleEntry('list'),
  [actions.DELETE_COMMENT]: updateSingleEntry('list'),
  [strategyComparisonActions.UPDATE_STRATEGY_COMPARISON]: updateKeyList('strategies'),
  [portfolioActions.FETCH_PORTFOLIO]: updateStrategyList,
  [portfolioActions.UPDATE_PORTFOLIO]: updateStrategyList,
});
