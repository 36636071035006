import { userActions } from 'store/actions';
// import { createReducer } from 'store/reducers/utilities'

// const { session } = initialState

// const setCurrentUser = (state, { user }) => {
//   return {
//     ...state,
//     currentUser: user.id
//   }
// }

const initState = () => ({
  session: {
    currentUser: null,
  },
});

export default function session(state = initState(), action = {}) {
  switch (action.type) {
    case userActions.SET_CURRENT_USER_INFO: {
      const { user } = action.payload;

      return {
        ...state,
        currentUser: user.id,
      };
    }
    case 'RESET_STORE': {
      const newState = initState();
      return {
        ...newState,
      };
    }
    default:
      return state;
  }
}
// export const sessionReducer = createReducer(session, {
//   [userActions.SET_CURRENT_USER_INFO]: setCurrentUser
// })
