import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { hideMessage } from 'store/action-creators/flashMessages';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

let displayed = [];

const Notifier = () => {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.flashMessages || []);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const storeDisplayed = (id) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  React.useEffect(() => {
    notifications.forEach(({
      id, message, type, options = {}, dismissed = false,
    }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(id);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(id)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        id,
        variant: type,
        ...options,
        action: (key) => (
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className="snackbar-close-button"
            onClick={() => {
              closeSnackbar(key);
              dispatch(hideMessage(id));
              removeDisplayed(key);
            }}
          >
            <CloseIcon />
          </IconButton>
        ),
        onClose: (event, reason, myKey) => {
          if (options.onClose) {
            options.onClose(event, reason, myKey);
          }
        },
        onExited: (_, myKey) => {
          // remove this snackbar from redux store
          dispatch(hideMessage(id));
          removeDisplayed(myKey);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(id);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

  return null;
};

export default Notifier;
