import React from 'react';
import { Grid, Button } from '@material-ui/core';

const ErrorFallback = () => (
  <Grid
    container
    alignItems="center"
    justify="center"
    direction="column"
    style={{ margin: '100px' }}
  >
    <h2>
      Uh-oh, looks like something didn&apos;t quite go as expected!
    </h2>
    <h4>
      We are currently working to fix that.
    </h4>
    <h4>
      You can keep browsing or try
      {' '}
      <Button
        type="button"
        variant="contained"
        color="primary"
        style={{ margin: '0 10px' }}
        onClick={() => window.location.reload()}
      >
        refreshing the page
      </Button>
    </h4>
  </Grid>

);

export default ErrorFallback;
