import React, { useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getCurrentUserInfo } from 'store/action-creators/auth/auth';
import { replace } from 'connected-react-router';
import { resetStore } from 'store/reducers/utilities';

export const AuthGuardMemberFunctional = ({ children }) => {
  const [state, setState] = React.useState({
    loading: true,
    hasError: false,
  });

  const dispatch = useDispatch();
  let __isMounted = true;
  const location = useLocation();

  useLayoutEffect(() => {
    async function fetchUser() {
      const response = await dispatch(getCurrentUserInfo(location.pathname));

      if (response) {
        if (!response.is_active && !response.is_superuser) {
          if (__isMounted) {
            setState({
              loading: false,
              hasError: true,
            });
            dispatch(resetStore());
            dispatch(replace('/login'));
          }
        } else if (__isMounted) {
          setState({
            loading: false,
            hasError: false,
          });
        }
      } else {
        // dispatch(replace('/login'));
      }
    }

    fetchUser();

    return () => (__isMounted = false);
  }, []);

  if (state.loading) {
    return (
      <div className="flex h-screen items-center">
        <div className="w-screen text-3xl text-center text-grey">
          Loading...
        </div>
      </div>
    );
  }

  return <>{children}</>;
};
