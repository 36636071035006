import { riskActions as actions } from 'store/actions';
import {
  createReducer,
  initState,
  updateEntry,
  deleteEntry,
  updateSingleEntry,
} from 'store/reducers/utilities';

export const riskReducer = createReducer(initState(), {
  [actions.FETCH_ALL_RISKS]: updateEntry,
  [actions.CREATE_RISK]: updateEntry,
  [actions.UPDATE_RISK]: updateEntry,
  [actions.DELETE_RISK]: deleteEntry('list'),
  [actions.CREATE_COMMENT]: updateSingleEntry('list'),
  [actions.DELETE_COMMENT]: updateSingleEntry('list'),
});
