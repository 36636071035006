import { strategyComparisonActions, portfolioActions } from 'store/actions';
import {
  createReducer,
  initState,
  updateEntry,
  updateKeyList,
  updateListEntry
} from 'store/reducers/utilities';
import { get, unionBy } from 'lodash';

const updateStrategyComparison = (state, { payload, type }) => {
  const oldList = state.list.slice();
  const newList = oldList.map((item, index) => {
    // Replace the item at index 2
    if (item.name === payload.name) {
      return payload;
    }
    // Leave every other item unchanged
    return item;
  });
  return {
    ...state,
    list: newList,
  };
};

const updateStrategyComparisonList = (state, { payload }) => {
  const data = (payload && payload.data) ? payload.data : payload;
  let strategy_comparisons = [];

  if (Array.isArray(data)) {
    strategy_comparisons = data.map(
      (portfolio) => portfolio.strategies.map(
        (strategy) => strategy.strategy_comparisons
      ).flat()
    ).flat();
  } else if (data && data.strategies) {
    strategy_comparisons = data.strategies.map(
      (strategy) => strategy.strategy_comparisons
    ).flat();
  }

  const mergedLists = unionBy(strategy_comparisons, state.list, 'id');

  return {
    ...state,
    list: mergedLists,
  };
};

export const strategyComparisonReducer = createReducer(initState(), {
  [portfolioActions.FETCH_PORTFOLIO]: updateStrategyComparisonList,
  [portfolioActions.PROVISION_DEMO_CONTENT]: updateStrategyComparisonList,
  [portfolioActions.CREATE_PORTFOLIO]: updateStrategyComparisonList,
  [strategyComparisonActions.UPDATE_STRATEGY_COMPARISON]: updateKeyList(
    'strategy_comparisons'
  ),
});
