import { applyMiddleware, compose, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import ReduxThunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import { createRootReducer } from 'store/reducers';

export const history = createBrowserHistory();
const routerMiddle = routerMiddleware(history);

const middlewares = [ReduxThunk, routerMiddle];

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger();
  middlewares.push(logger);
}

const enhancers = [applyMiddleware(...middlewares)];

if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['router', 'flashMessages', 'form'],
};

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history)
);

export const Store = createStore(persistedReducer, {}, compose(...enhancers));

export const persistor = persistStore(Store);
