import { organizationActions as actions } from '../../actions';

const initState = () => ({
  pending: false,
  organization: {},
  list: [],
  all: [],
  error: null,
});

const getOrganizations = (state, key, val) => ({
  ...initState().status,
  ...{ [key]: val },
});

const getError = (state, key, val) => ({
  ...state.error,
  ...{ [key]: val },
});

export default function organizations(state = initState(), action = {}) {
  switch (action.type) {
    case actions.CREATE_ORGANIZATION:
      return {
        ...state,
        ...{
          organization: action.payload,
        },
      };
    case actions.UPDATE_ORGANIZATION:
      return {
        ...state,
        ...{
          organization: action.payload,
        },
      };
    case 'ORGANIZATIONS_DELETE_PENDING':
      return {
        ...state,
        ...{
          pending: getOrganizations(state, 'pending', action.payload),
          error: getError(state, 'error', action.payload),
        },
      };
    case 'ORGANIZATIONS_DELETE_SUCCESS':
      return {
        ...state,
        ...{
          created_data: action.payload,
          error: getError(state, 'error', action.payload),
        },
      };
    case 'ORGANIZATIONS_DELETE_ERROR':
      return {
        ...state,
        ...{
          error: getError(state, 'error', action.payload),
        },
      };
    case 'ORGANIZATIONS_GET_PENDING':
      return {
        ...state,
        ...{
          pending: getOrganizations(state, 'pending', action.payload),
          error: getError(state, 'error', action.payload),
        },
      };
    case 'ORGANIZATIONS_GET_SUCCESS':
      console.log(action.payload);
      return {
        ...state,
        ...{
          list: action.payload,
          error: getError(state, 'error', action.payload),
        },
      };
    case 'ORGANIZATIONS_GET_ERROR':
      return {
        ...state,
        ...{
          error: getError(state, 'error', action.payload),
        },
      };
    case actions.FETCH_ALL_ORGANIZATIONS:
      return {
        ...state,
        ...{
          all: action.payload,
        },
      };
    case actions.FETCH_ORGANIZATION:
      return {
        ...state,
        ...{
          organization: action.payload,
          error: getError(state, 'error', action.payload),
        },
      };
    case actions.CREATE_ORGANIZATION_USER:
      return {
        ...state,
        ...{
          error: getError(state, 'error', action.payload),
        },
      };
    case 'RESET_STORE': {
      const newState = initState();
      return {
        ...newState,
      };
    }
    default:
      return state;
  }
}
