import React from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';

export function PageTitle(props) {
  if (!props.intlId) {
    throw Error('PageTitle component is missing `intlId` property from props.');
  }

  return (
    <FormattedMessage id={props.intlId}>
      {(internationalisedTitle) => (
        <Helmet>
          <title>
            {`
              ${process.env.REACT_APP_NAME}
              ${' | '}
              ${internationalisedTitle}
              ${props.entityName ? ` - ${props.entityName}` : '' }
            `}
          </title>
        </Helmet>
      )}
    </FormattedMessage>
  )
}
