export const createPortfolioSteps = [
  {
    target: '#create-demo-portfolio',
    content: 'Create demo portfolio goodies here',
    disableBeacon: true,
  },
  {
    target: '#create-new-portfolio',
    content: 'We recommended following the tour with the demo project, but once you are ready you can create a new portfolio here',
  },
  {
    target: '#portfolio-actions',
    content: 'Portfolio action buttons live here, try clicking on "prioritization" next to continue the tour',
  },
];

export const portfolioPrioritySteps = [
  {
    target: '#strategy-table',
    content: 'This is the strategy table, it\'s recommended that you create between 3-10 strategies',
    disableBeacon: true,
  },
  {
    target: '#create-new-strategy-button',
    content: 'Create a new strategy here',
  },
  {
    target: '.strategy-comparison',
    content: 'Here you can use the sliders to compare the strategies against each other',
  },
  {
    target: '#risk-table',
    content: 'This is the risk table, here you can add risks to the portfolio, defined by a probability (1-4) and an impact (1-5).',
  },
  {
    target: '#project-table',
    content: 'This is the project table, here you can create the projects you want to consider in this portfolio',
  },
  {
    target: '#create-new-project-button',
    content: 'Create a new project here',
  },
  {
    target: '.project-comparison',
    content: 'Here you can compare the projects to the strategies above, depending on how they align (no - extrene alignment)',
  },
  {
    target: '#risks_input',
    content: 'Here you can add risks that the undertaking of this project will meaningfully mitigate',
  },
  {
    target: '#value-risk-input',
    content: 'Here is the portfolio value-to-risk ratio, 100 is 100% strategic alignment, 0 is 100% risk mitigation, You can view the output of all values represented in the Pie Chart, 100% strategic alignment, 100% Risk Mitigation and the ratio you have chosen using the slider',
  },
  {
    target: '#portfolio-tabs',
    content: 'Portfolio tabs - Once you are finished on this tab you can move to the optimization tab to explore further',
    disableBeacon: true,
  },
  // {
  //   target: '#project-chart-buttons',
  //   content: 'Here you can see the pie chart breakdown for Strategic Alignment, Risk Mitigation, and the composite score defined by the value-to-risk ratio',
  // },
];

export const portfolioOptimizationSteps = [
  {
    target: '#portfolio-optimizer-header',
    content: 'Here is where you can optimize your portfolio given a budget constraint',
  },
  {
    target: '#project-composite-score',
    content: 'This is the composite value/risk score from the previous screen',
  },
  {
    target: '#project-cost',
    content: 'Here is the budget of the project, you can edit this directly',
  },
  {
    target: '#project-force-in',
    content: 'You can decide to force a project in, force it out, or let the algorithm decide',
  },
  {
    target: '#project-status',
    content: 'This is the result, given the budget and the other parameters, should you include the project or not?',
  },
  {
    target: '#budget-constraint',
    content: 'Here you can input the overall budget constraint, save by hitting enter',
  },
  {
    target: '#optimize-radar-chart',
    content: 'This is the percentage of the overall budget vs the percentage of the overall value generated by each project, if the red value (cost) is smaller than the tan value (value generated) then you are generating more value per $ spent',
  },
  {
    target: '#project-chart-tab-0',
    content: 'Here can see the breakdown of value generation and cost per project and whether it has been moved in or out, similar information to the radar chart above',
  },
  {
    target: '#project-chart-tab-1',
    content: 'Here you can see all possible solutions, the efficient frontier is the line connecting all the solutions across the top, here it is useful to look for small gains in money, that offer large gains in value generation, try inputting different budget constraints to get a feel.',
  },
  {
    target: '#project-chart-tab-2',
    content: 'This chart shows the strategic alignment of the optimal solution, as a percentage of $ spent - what is the proportion of money spent on each strategic driver (across all moved in projects)',
  },
  {
    target: '.project-link',
    content: 'You can take a deeper dive on each project by clicking on the project name here.',
  },
];

export const projectPrioritizationSteps = [
  {
    target: '#new-task',
    content: 'Create a new task here',
  },
  {
    target: '#task-comparison',
    content: 'Here you can use the sliders to compare the tasks against the strategic drivers from the portfolio',
  },
  {
    target: '#risks_input',
    content: 'Here you can add risks that this task will mitigate, same as at the portfolio level',
  },
  {
    target: '#value-to-risk-input',
    content: 'Here is the project value-to-risk ratio, 100 is 100% strategic alignment, 0 is 100% risk mitigation',
  },
  {
    target: '#task-chart-switch',
    content: 'Here you can see the pie chart breakdown for Strategic Alignment, Risk Mitigation, and the composite score defined by the value-to-risk ratio, similar to the portfolio level',
  },
  {
    target: '#project-tabs',
    content: 'Switch between project prioritzation, optimization and simulation here, try clicking on Simulation to continue the tour',
    disableBeacon: true,
  },
];

export const projectOptimizationSteps = [
  {
    target: '#task-row',
    content: 'This is a row in the task queue, the order of the rows here is dictated by the composite score, and is the order the tasks are fed into the simulator',
    disableBeacon: true,
  },
  {
    target: '#value-to-risk-input',
    content: 'Value to Risk Ratio, here may also adjust the project value to risk ratio from the prioritization screen, allowing you to see the effect on your optimized plan immediately',
  },
  {
    target: '#time-constraint',
    content: 'Time Constraint can be adjusted here, adjusting this value will show you what tasks can be completed within the allotted amount of intervals (days/weeks/years - depending on your input tasks), it will also total up the amount of strategic value that will be generated.',
  },
];

export const projectSimulationSteps = [
  {
    target: '#project-simulator',
    content: 'Here is the project simulator, you can create a process here and add resources to nodes, try the play button at the bottom once you are ready to simulate your tasks going through the process.',
    disableBeacon: true,
  },
  {
    target: '#project-tabs',
    content: 'Switch between project prioritzation, optimization and simulation here, try clicking on Optimization to continue the tour',
    disableBeacon: true,
  },
];
