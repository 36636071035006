import { portfolioActions as actions } from 'store/actions';
import {
  createReducer, deleteEntry,
  initState,
  updateEntry,
  updateSingleEntry,
} from 'store/reducers/utilities';

const updatePortfolios = (state, { payload }) => {
  const portfolios = payload.data.map((prog) => {
    const { projects, strategies, ...rest } = prog;
    return rest;
  });

  return {
    ...state,
    list: portfolios,
  };
};

export const portfolioReducer = createReducer(initState(), {
  [actions.FETCH_ALL_PORTFOLIOS]: updatePortfolios,
  [actions.FETCH_PORTFOLIO]: updateSingleEntry('list'),
  [actions.UPDATE_PORTFOLIO]: updateSingleEntry('list'),
  [actions.DELETE_PORTFOLIO]: deleteEntry('list'),
  [actions.CREATE_PORTFOLIO]: updateEntry,
  [actions.PROVISION_DEMO_CONTENT]: updateEntry,
});
