import { initialState } from 'store/initialState';
import { flashMessageActions as actions } from 'store/actions';
import { createReducer } from 'store/reducers/utilities';

const { flashMessages } = initialState;

const showMessage = (state, action) => {
  return [
    ...state,
    {
      id: action.id,
      type: action.messageType,
      message: action.message,
    },
  ];
};

const hideMessage = (state, { id }) => state.filter((msg) => msg.id !== id);

export const flashMessagesReducer = createReducer(flashMessages, {
  [actions.SHOW_MESSAGE]: showMessage,
  [actions.HIDE_MESSAGE]: hideMessage,
  RESET_STORE: () => []
});
