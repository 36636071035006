import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AppFooter, AppHeader } from '../components';

export const DashboardLayout = ({ children }) => (
  <div>
    <AppHeader />
    <div className="max-w-2xl mx-auto mt-10 px-4">{children}</div>
    <AppFooter />
  </div>
);

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    background: '#F5F6F8',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(0, 3),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100vw',
      padding: theme.spacing(0, 1),
    },
  },
}));

export const NewDashboardLayout = ({ children }) => {
  const classes = useStyles();
  return (
    <>
      <AppHeader />
      <div className={classes.root}>
        <main className={classes.content}>
          <Grid container>
            <Grid item xs={12}>
              <div>{children}</div>
            </Grid>
          </Grid>
        </main>
        {/* <AppFooter /> */}
      </div>
    </>
  );
};
