import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { linkStyle } from 'constants/styles';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
  AppFooterCustom: {
    backgroundColor: theme.palette.primary.main,
    marginTop: 20,
    position: 'absolute',
    width: '100%',
    flexShrink: 0,
  },
}));

export const AppFooter = (props) => {
  const classes = useStyles();
  return (
    <footer className={classes.AppFooterCustom}>
      <p className="text-center text-sm text-grey py-6">
        <FormattedMessage id="footer.madeby" defaultMessage="Made by"/>{' '}
        <a
          className={linkStyle}
          target="_blank"
          rel="noopener noreferrer"
          href="https://pandarocketpunch.com"
        >
          Panda Rocket Punch, LLC
        </a>
      </p>
    </footer>
  );
};
