export const initialState = {
  entities: {
    users: {},
  },
  session: {
    currentUser: null,
  },
  flashMessages: [],
  requests: {},
};
