import React from 'react';
import messages_nl from 'translations/nl.json';
import messages_en from 'translations/en.json';
import { IntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';

export const IntlProviderComponent = ({ children }) => {
  const messages = {
    nl: messages_nl,
    en: messages_en,
  };

  let language = navigator.language.split(/[-_]/)[0];
  const user = useSelector((state) => state.auth.user);
  if (user && user.language) {
    language = user.language;
  }

  return (
    <IntlProvider
      key={language}
      locale={language}
      messages={messages[language]}
    >
      {children}
    </IntlProvider>
  );
};
