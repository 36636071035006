import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Button,
  Grid
} from '@material-ui/core';
import { Logo, UserCard } from 'components';

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: 'linear-gradient(90deg, #323C47 15.63%, #00BE9C 86.56%)',
  },
  portfolioLink: {
    fontSize: 14,
    color: 'white',
    textDecoration: 'none',
  },
}));

export const AppHeader = () => {
  const classes = useStyles();

  return (
    <>
      <AppBar
        position="sticky"
        className={classes.appBar}
      >
        <Toolbar>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Logo />
            </Grid>
            <Grid item>
              <Grid container>
                <Link style={{ textDecoration: 'none' }} to="/portfolios">
                  <Button className={classes.portfolioLink}>
                    My Portfolios
                  </Button>
                </Link>
                <UserCard />
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};
