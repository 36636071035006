import React, { useState, useEffect } from 'react';
import Joyride from 'react-joyride';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  createPortfolioSteps,
  portfolioPrioritySteps,
  portfolioOptimizationSteps,
  projectPrioritizationSteps,
  projectOptimizationSteps,
  projectSimulationSteps,
} from './steps';

const AppTour = (props) => {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const showTour = user && user.show_tour;
  const [run, setRun] = useState(false);
  const [steps, setSteps] = useState([]);

  const setAppTourSeen = (step) => {
    // once tour has been seen or dismissed, handle appropriately, set in db? local storage?
    console.log(('setAppTourSeen', { step }));
  };

  useEffect(() => {
    if (!showTour) {
      return () => false;
    }

    if (window.location.pathname === '/' || window.location.pathname === '/portfolios') {
      setSteps(createPortfolioSteps);
      setAppTourSeen('portfolios');
    }
    if (window.location.pathname.includes('portfolio-prioritization')) {
      setSteps(portfolioPrioritySteps);
      setAppTourSeen('portfolio-prioritization');
    }
    if (window.location.pathname.includes('portfolio-optimization')) {
      setSteps(portfolioOptimizationSteps);
      setAppTourSeen('portfolio-optimization');
    }
    if (window.location.pathname.includes('project-prioritization')) {
      setSteps(projectPrioritizationSteps);
      setAppTourSeen('project-prioritization');
    }
    if (window.location.pathname.includes('project-optimization')) {
      setSteps(projectOptimizationSteps);
      setAppTourSeen('project-optimization');
    }
    if (window.location.pathname.includes('project-simulation')) {
      setSteps(projectSimulationSteps);
      setAppTourSeen('project-simulation');
    }
    const timer = setTimeout(() => setRun(true), 1000);
    return () => {
      setRun(false);
      clearTimeout(timer);
    };
  }, [location]);

  return (
    <Joyride
      // callback={setAppTourSeen}
      run={run}
      steps={steps}
      continuous
      showProgress
      showSkipButton
      scrollOffset={275}
      styles={{
        options: {
          zIndex: 2000,
        },
      }}
      {...props}
    />
  );
};

export default AppTour;
