import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import * as Sentry from '@sentry/react';
import { Routes } from 'Routes';
import { PersistGate } from 'redux-persist/es/integration/react';
import { history, persistor, Store } from 'store/create-store';
import {
  IntlProviderComponent,
  ModalProviderWrapper,
  ModalRoot,
} from 'components';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import { FormattedMessage } from 'react-intl';
import { CssBaseline } from '@material-ui/core';
import ErrorFallback from 'components/Error/ErrorFallback';
import Notifier from 'components/FlashMessages/Notifier';
import AppTour from 'components/AppTour/AppTour';
import theme from './theme';
import { initTorque } from '@torquecloud/torque-js'
import { TorqueProvider } from '@torquecloud/torque-react-js';


const torqueInitOptions = {
  apiPublicKey: `${process.env.REACT_APP_TORQUE_PUBLIC_KEY}`,
  authCallbackUrl: `${window.location.origin}/torque/auth-callback`,
  fallbackUrl: `${window.location.origin}`,
}

const torque = initTorque(torqueInitOptions);
torque.then(result => {
  if(result.error){
    console.error('Projsim error handling of Torque initialization error.', result.error)
  }
})

const Loading = () => (
  <div className="flex h-screen items-center">
    <div className="w-screen text-3xl text-center text-grey">
      <FormattedMessage id="loading" defaultMessage="Loading..." />
    </div>
  </div>
);

export const App = () => (
  <Provider store={Store}>
    <PersistGate persistor={persistor}>
      <IntlProviderComponent>
        <TorqueProvider torque={torque}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider>
              <Sentry.ErrorBoundary fallback={ErrorFallback}>
                <Suspense fallback={<Loading />}>
                  <ConnectedRouter history={history}>
                    <ModalProviderWrapper>
                      <AppTour />
                      <Notifier />
                      <ModalRoot />
                      <CssBaseline />
                      <Routes />
                    </ModalProviderWrapper>
                  </ConnectedRouter>
                </Suspense>
              </Sentry.ErrorBoundary>
            </SnackbarProvider>
          </ThemeProvider>
        </TorqueProvider>
      </IntlProviderComponent>
    </PersistGate>
  </Provider>
);
