import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { flashMessage } from 'store/action-creators/flashMessages';
import { push } from 'connected-react-router';
import { isString } from 'lodash';

const errorMessages = defineMessages({
  400: {
    id: 'error.400',
    defaultMessage: 'Looks like you made a mistake inputting data.',
  },
  401: {
    id: 'error.401',
    defaultMessage: 'Uh-oh, you must login first.',
  },
  403: {
    id: 'error.403',
    defaultMessage: 'You are not authorized to perform this action.',
  },
  404: {
    id: 'error.404',
    defaultMessage: 'An error occurred, please try again later',
  },
  405: {
    id: 'error.405',
    defaultMessage: 'You cannot perform this action, please try again later',
  },
  500: {
    id: 'error.500',
    defaultMessage: 'A server error occurred, please try again later.',
  },
  generic: {
    id: 'error.generic',
    defaultMessage: 'An error occurred, please try again later.',
  },
});

const errorHandler = (dispatch, status = 'generic', data) => {
  if (status !== 422) {
    if (isString(data.detail)) {
      errorMessages[status].defaultMessage = data.detail;
    }
    dispatch(
      flashMessage(
        'error',
        <FormattedMessage {...errorMessages[status]} />,
      ),
    );
  } else {
    data.detail.map((detail) => dispatch(
      flashMessage(
        'error',
        <FormattedMessage
          id="error.422"
          defaultMessage={detail.msg}
        />,
      ),
    ));
  }

  if (status === 401) dispatch(push('/login'));
  if (status === 403) dispatch(push('/login'));
};

export default errorHandler;
