import { unionBy, get } from 'lodash';
import {
  projectActions as actions,
  projectMatricesActions,
  portfolioActions,
} from 'store/actions';
import {
  createEntry,
  createKey,
  createReducer,
  deleteEntry,
  initState,
  updateEntry,
  updateKey,
  updateMergeLists,
  updateSingleEntry,
} from 'store/reducers/utilities';

const projectInitState = () => {
  const baseState = initState();
  baseState.nodes = [];
  baseState.events = [];
  baseState.tasks = [];
  baseState.timeSeries = [];
  baseState.solution = [];
  return baseState;
};

const update = (state, { payload }) => {
  if (!payload) {
    return {
      ...state,
    };
  }
  return {
    ...state,
    data: payload.data,
    // timeSeries: payload.data,
  };
};

const createNode = (state, { payload }) => {
  if (!payload) return { ...state };

  const newNodes = state.nodes.map((node) => {
    if (payload.link_from.length > 0 && (node.id === payload.link_from[0].id)) {
      return {
        ...node,
        link_to: [...node.link_to, payload],
      };
    }
    return node;
  });

  return {
    ...state,
    nodes: [...newNodes, payload],
  };
};

const deleteNode = (state, { payload }) => {
  if (!payload) return { ...state };

  const newNodes = state.nodes
    .filter((node) => node.id !== payload.id)
    .map((node) => {
      const linkTo = node.link_to.filter(({ id }) => id !== payload.id);

      return {
        ...node,
        link_to: linkTo,
      };
    });

  return {
    ...state,
    nodes: newNodes,
  };
};

const updateProjectList = (state, { payload }) => {
  const data = (payload && payload.data) ? payload.data : payload;
  let projects = [];

  if (Array.isArray(data)) {
    projects = data.map(
      ({ projects }) => projects.map((proj) => proj),
    ).flat();
  } else {
    projects = get(data, 'projects');
  }

  const mergedLists = unionBy(projects, state.list, 'id');

  return {
    ...state,
    list: mergedLists,
  };
};

export const projectReducer = createReducer(projectInitState(), {
  [actions.FETCH_ALL_PROJECTS]: updateEntry,
  [actions.FETCH_PROJECT]: updateEntry,
  [actions.UPDATE_PROJECT]: updateSingleEntry('list'),
  [actions.UPDATE_PROJECT_COMPARISON]: updateEntry,
  [actions.DELETE_PROJECT]: deleteEntry('list'),
  [actions.CREATE_PROJECT]: createEntry('list'),
  [actions.SIMULATE_PROJECT]: update,
  [actions.FETCH_NODES]: updateKey('nodes'),
  [actions.CREATE_NODE]: createNode,
  [actions.UPDATE_NODE]: updateSingleEntry('nodes'),
  [actions.DELETE_NODE]: deleteNode,
  [actions.CREATE_TASK]: createKey('tasks'),
  [actions.UPDATE_TASK]: updateSingleEntry('tasks'),
  [actions.DELETE_TASK]: deleteEntry('tasks'),
  [actions.FETCH_NODE_EVENTS]: updateKey('events'),
  [actions.CREATE_NODE_EVENT]: createEntry('events'),
  [actions.UPDATE_NODE_EVENT]: updateSingleEntry('events'),
  [actions.DELETE_NODE_EVENT]: deleteEntry('events'),
  [actions.UPLOAD_PROJECT_FILE]: update,
  [actions.CREATE_COMMENT]: updateSingleEntry('list'),
  [actions.DELETE_COMMENT]: updateSingleEntry('list'),
  [portfolioActions.OPTIMIZE_PORTFOLIO]: updateKey('solution'),
  [portfolioActions.FETCH_PORTFOLIO]: updateProjectList,
  [portfolioActions.UPDATE_PORTFOLIO]: updateProjectList,
});
