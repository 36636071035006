import _ from 'lodash';

const exampleStrategies = [
  {
    name: 'Improve Operational Uptime',
    number: 1,
    percentage: 0,
    normalizedSum: 0,
  },
  {
    name: 'Improve End User Experience (UI)',
    number: 2,
    percentage: 0,
    normalizedSum: 0,
  },
  {
    name: 'Improve Development Efficiency',
    number: 3,
    percentage: 0,
    normalizedSum: 0,
  },
  {
    name: 'Improve Cost Management/Revenue Flow',
    number: 4,
    percentage: 0,
    normalizedSum: 0,
  },
  {
    name: 'Increase Market Depth/Reach',
    number: 5,
    percentage: 0,
    normalizedSum: 0,
  },
  {
    name: 'Improve Brand Awareness',
    number: 6,
    percentage: 0,
    normalizedSum: 0,
  },
];

const exampleProjects = [
  {
    name: 'Website Redesign',
    number: 1,
    percentage: 0,
    sum: 0,
  },
  {
    name: 'New Marketing Material (print)',
    number: 2,
    percentage: 0,
    sum: 0,
  },
];

const initState = () => ({
  strategyMatrix: [
    [10, 10, 10, 10, 10, 10],
    [10, 10, 10, 10, 10, 10],
    [10, 10, 10, 10, 10, 10],
    [10, 10, 10, 10, 10, 10],
    [10, 10, 10, 10, 10, 10],
    [10, 10, 10, 10, 10, 10],
  ],
  strategyMatrixTotals: [[60, 60, 60, 60, 60, 60]],
  strategies: exampleStrategies,
  projectMatrix: [
    [10, 10, 10, 10, 10, 10],
    [10, 10, 10, 10, 10, 10],
  ],
  projectMatrixTotals: [[60, 60, 60, 60, 60, 60]],
  projects: exampleProjects,
  orderStatus: {
    pending: false,
    error: false,
    success: false,
  },
  status: {
    empty: true,
    notEmpty: 'init',
    paid: 'init',
    error: 'init',
  },
});

const inverseTable = [
  {
    value: 90,
    inverse: 1
  },
  {
    value: 60,
    inverse: 3
  },
  {
    value: 30,
    inverse: 6
  },
  {
    value: 10,
    inverse: 10
  },
  {
    value: 6,
    inverse: 30
  },
  {
    value: 3,
    inverse: 60
  },
  {
    value: 1,
    inverse: 90
  },
];

// array of arrays and sum columns to create new array 1 X length of array given stored in strategyMatrixTotals
const getColumnTotals = (matrix) => {
  const totals = [];
  _.each(matrix, (row, index) => {
    totals[index] = _.sum(_.map(matrix, (row) => row[index]));
  });

  return totals;
};

const getRowTotals = (matrix, columnTotals) => {
  const totals = [];
  _.each(matrix, (row, matrixIndex) => {
    totals[matrixIndex] = _.sum(
      _.map(row, (rowValue, rowIndex) =>
        Math.round((rowValue / columnTotals[rowIndex]) * 10000)
      )
    );
  });

  return totals;
};

const getProjectMatrixRowTotals = (matrix, strategies) => {
  const totals = [];
  _.each(matrix, (row, matrixIndex) => {
    totals[matrixIndex] = _.sum(
      _.map(row, (rowValue, rowIndex) =>
        Math.round(
          rowValue *
          (strategies[rowIndex].normalizedSum / strategies.length) *
          10000
        )
      )
    );
  });

  return totals;
};

const getProjectTotalsSum = (projects) => {
  return _.sum(
    _.map(projects, (project) => {
      console.log(project.sum);
      return project.sum;
    })
  );
};

export default function projectPriority(state = initState(), action = {}) {
  switch (action.type) {
    case 'ADD_STRATEGY': {
      const strategies = state.strategies.slice();
      let strategyMatrix = state.strategyMatrix.slice();
      const strategyName = action.payload;

      const strategiesCount = strategies.length + 1;
      const newStrategy = {
        name: strategyName,
        number: strategiesCount,
        percentage: 0,
        normalizedSum: 0,
      };

      strategyMatrix = _.each(strategyMatrix, (matrixRow) => {
        matrixRow.push(10);
      });

      const newStrategyArray = new Array(strategiesCount).fill(10);

      return {
        ...state,
        ...{
          strategies: [...strategies, newStrategy],
        },
        ...{
          strategyMatrix: [...strategyMatrix, newStrategyArray],
        },
      };
    }
    case 'REMOVE_STRATEGY': {
      // const strategies = state.strategies.slice();
      // let strategyMatrix = state.strategyMatrix.slice();
      // const {name, number} = action.payload;
      //
      // const strategiesCount = strategies.length + 1;
      //
      // strategyMatrix = _.each(strategyMatrix, (matrixRow) => {
      //   matrixRow.push(10);
      // });
      //
      // const newStrategyArray = new Array(strategiesCount).fill(10);

      return {
        ...state,
      };
    }
    case 'UPDATE_STRATEGY_MATRIX': {
      const strategyMatrix = state.strategyMatrix.slice();
      const { row, column, value } = action.payload;

      strategyMatrix[row][column] = value;
      strategyMatrix[column][row] = _.find(inverseTable, { value }).inverse;

      return {
        ...state,
        ...{
          strategyMatrix,
        },
      };
    }
    case 'CALCULATE_STRATEGIC_PRIORITIES': {
      const strategyMatrix = state.strategyMatrix.slice();
      const strategies = state.strategies.slice();

      const strategiesCount = strategies.length;

      const newTotalsArray = getColumnTotals(strategyMatrix);
      const rowTotals = getRowTotals(strategyMatrix, newTotalsArray);

      _.each(strategies, (strategy) => {
        strategy.percentage = Math.round(
          rowTotals[strategy.number - 1] / strategiesCount
        );
        strategy.normalizedSum = Math.round(rowTotals[strategy.number - 1]);
      });

      return {
        ...state,
        ...{
          strategies: [...strategies],
        },
        ...{
          strategyMatrixTotals: [...newTotalsArray],
        },
      };
    }
    case 'ADD_PROJECT': {
      const projects = state.projects.slice();
      const strategies = state.strategies.slice();
      const projectMatrix = state.projectMatrix.slice();
      const projectName = action.payload;

      const projectsCount = projects.length + 1;
      const newProject = {
        name: projectName,
        number: projectsCount,
        percentage: 0,
        sum: 0,
      };

      const newProjectArray = new Array(strategies.length).fill(10);

      return {
        ...state,
        ...{
          projects: [...projects, newProject],
        },
        ...{
          projectMatrix: [...projectMatrix, newProjectArray],
        },
      };
    }
    case 'REMOVE_PROJECT': {
      // const projects = state.projects;
      // let projectMatrix = state.projectMatrix.slice();
      // const {name, number} = action.payload;
      //
      // const projectsCount = projects.length + 1;
      //
      // projectMatrix = _.each(projectMatrix, (matrixRow) => {
      //   matrixRow.push(10);
      // });
      //
      // const newProjectArray = new Array(projectsCount).fill(10);

      return {
        ...state,
      };
    }
    case 'UPDATE_PROJECT_MATRIX': {
      const projectMatrix = state.projectMatrix.slice();
      const { row, column, value } = action.payload;

      projectMatrix[row][column] = value;

      return {
        ...state,
        ...{
          projectMatrix,
        },
      };
    }
    case 'CALCULATE_PROJECT_PRIORITIES': {
      const { projectMatrix } = state;
      const { strategies } = state;
      let projects = state.projects.slice();

      // Project Sum
      const rowTotals = getProjectMatrixRowTotals(projectMatrix, strategies);
      // Sum of all Projects
      let totalsSum = getProjectTotalsSum(projects);

      // project percentage = projectsum/totalsum
      projects = _.each(projects, (project) => {
        project.sum = Math.round(rowTotals[project.number - 1]);
        totalsSum = getProjectTotalsSum(projects);
        project.percentage = Math.round(
          (rowTotals[project.number - 1] / totalsSum) * 10000
        );
      });

      return {
        ...state,
        ...{
          projects: [...projects],
        },
        ...{
          projectMatrixTotals: [...rowTotals],
        },
      };
    }
    case 'RESET_STORE': {
      const newState = initState();
      return {
        ...newState,
      };
    }
    default:
      return state;
  }
}
