import React from 'react';
import { push, replace } from 'connected-react-router';
import { flashMessage } from 'store/action-creators/flashMessages';
import { FormattedMessage } from 'react-intl';
import { apiRequestAction } from 'store/action-creators/requests/apiRequests';
import _ from 'lodash';

export const getCurrentUserInfo = (redirect) => async (dispatch) => {
  return dispatch(
    apiRequestAction({
      action: 'AUTH_GET_CURRENT_USER',
      endpoint: '/users/me',
      method: 'get',
    })
  )
    .then((res) => {
      // dispatch(getUserInfo(res.data));
      // if (res.data == null) {
      //   dispatch(push('login'));
      // }
      return res;
    })
    .catch((err) => {
      dispatch(resetStore());
      dispatch(replace(`/login?redirect=${redirect}`));
    });
};

export function getUserInfo(val) {
  return {
    payload: val,
    type: 'AUTH_GET_CURRENT_USER',
  };
}

export const uploadUserAvatar = (fileData, userId) => (dispatch) => {
  return dispatch(
    apiRequestAction({
      action: 'AUTH_AVATAR_UPLOAD_SUCCESS',
      endpoint: `/avatars/${userId}/upload`,
      method: 'post',
      data: fileData,
    })
  )
    .catch((error) => {
      dispatch(uploadUserAvatarFailed(error));
      return dispatch(
        flashMessage(
          'error',
          <FormattedMessage
            id="avatarUpload.failed"
            defaultMessage="The avatar upload has failed."
          />
        )
      );
    });
};

export function uploadUserAvatarFailed(val) {
  return {
    payload: val,
    type: 'AUTH_AVATAR_UPLOAD_FAIL',
  };
}

export const saveUser = (userData) => (dispatch) => {
  const { id } = userData;
  return dispatch(
    apiRequestAction({
      action: 'AUTH_SAVE_USER_SUCCESS',
      endpoint: `/users/me`,
      method: 'put',
      data: userData,
    })
  )
    .then((response) => {
      dispatch(getCurrentUserInfo());
      return dispatch(
        flashMessage(
          'success',
          <FormattedMessage
            id="updateUserAction.success"
            defaultMessage="Successfully updated profile."
          />
        )
      );
    })
    .catch((error) => {
      return dispatch(
        flashMessage(
          'error',
          <FormattedMessage
            id="updateUserAction.failed"
            defaultMessage="The update action has failed."
          />
        )
      );
    });
};

export const signup = (signUpData) => (dispatch) => {
  return dispatch(
    apiRequestAction({
      action: 'AUTH_SIGNUP_SUCCESS',
      endpoint: '/users/open',
      method: 'post',
      data: signUpData,
    })
  )
    .then((response) => {
      if (response.status === 200) {
        dispatch(getCurrentUserInfo());
        dispatch(
          flashMessage(
            'success',
            <FormattedMessage
              id="successFully.signup"
              defaultMessage="Successfully signed up."
            />
          )
        );
        dispatch(push('/'));
      }
    })
    .catch((error) => {
      if (error.response.status === 422) {
        dispatch(loginFail(error));
        if (_.get(error.response, 'data.messages.email')) {
          const intlId = error.response.data.messages.email[0];
          const intlMessage = <FormattedMessage id={intlId} />;
          dispatch(flashMessage('error', intlMessage));
        }
      }
    });
};

export function login(loginDetails, redirect) {
  return (dispatch) => {
    return dispatch(
      apiRequestAction({
        action: 'AUTH_LOGIN_SUCCESS',
        endpoint: '/login/access-token',
        method: 'post',
        data: loginDetails,
      })
    )
      .then((response) => {
        return dispatch(push(redirect));
      })
      .catch((err) => {

        dispatch(loginFail(err));
        // return dispatch(
        //   flashMessage(
        //     'error',
        //     <FormattedMessage
        //       id="unSuccessFully.login"
        //       defaultMessage="Incorrect login details."
        //     />
        //   )
        // );
      });
  };
}

export function loginTorque(torqueLoginDetails, redirect) {
  return (dispatch) => {
    return dispatch(
      apiRequestAction({
        action: 'AUTH_LOGIN_SUCCESS',
        endpoint: '/login/access-token-torque',
        method: 'post',
        data: torqueLoginDetails,
      })
    )
    .then((response) => {
      return dispatch(push(redirect));
    })
    .catch((err) => {
      dispatch(loginFail(err));
    });
  };
}

export function loginFail(err) {
  return {
    payload: err,
    type: 'AUTH_LOGIN_FAIL',
  };
}

export const logout = (torque, refreshTorqueUser) => (dispatch) => {
  // return dispatch(
  //   apiRequestAction({
  //     action: 'AUTH_LOGOUT_SUCCESS',
  //     endpoint: '/logout',
  //     method: 'get',
  //   })
  // )
  //   .then((response) => {
    torque.logout()
      .then(result => {
        refreshTorqueUser()
        dispatch(resetStore())
        dispatch(push('/'));
      })
    // })
    // .catch((err) => {
      // throw new Error(err);
      // return dispatch(enqueueSnackbar({
      //   message: err.message,
      //   options: {
      //     variant: 'error',
      //   },
      // }));
    // });
};

export function resetStore() {
  return {
    type: 'RESET_STORE',
  };
}
